<template>
  <div
    class="modal"
    v-if="value"
    :style="
      value ? 'display:block;background-color:#3333337a;' : 'display:none;'
    "
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex flex-row align-items-center">
          <div>
            <h3 class="modal-title" v-if="us_id > 0">
              Modifiez vos préférences personnelles
            </h3>
          </div>
        </div>
        <div class="modal-body" style="min-height:300px;">
          <div class="row">
            <div class="col-md-12">
              <div class="form-check">
                <label class="form-check-label" :for="helpBubbleId"
                  >Afficher les bulles d'aide</label
                >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="helpBubbleId"
                  name="helpBubble"
                  v-model="help"
                />
              </div>
              <m-form-select
                class="ms-3"
                style="width:260px;"
                label="Thème"
                name="theme"
                v-model="theme"
                :items="$store.state.items_themes"
              ></m-form-select>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex flex-row justify-content-end">
          <div class="d-flex flex-row align-items-center">
            <button type="button" class="btn btn-secondary " @click="cancelWin">
              Annuler
            </button>
            <button type="button" class="btn btn-primary ms-2" @click="saveWin">
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>

    <m-message-dialog
      v-model="dialogErr"
      title="Erreur"
      :text="dialogErrTxt"
    ></m-message-dialog>
  </div>
</template>

<script>
export default {
  name: "PreferencesPersonal",
  components: {},
  props: {
    us_id: Number,
    returnRoute: String,
    value: { default: false, type: Boolean }
  },
  data() {
    return {
      helpBubbleId: this.$Utils.randomstring("helpBubbleId"),
      dialogErr: false,
      dialogErrTxt: "",
      row_us: { us_rights: {}, us_type: "" },
      help: true,
      theme: ""
    };
  },
  watch: {
    value(v) {
      if (v) {
        // this.getContact();
        this.fileSelected = { image: null, binary: null };
        this.help = this.$store.state.preferences.personal.help;
        this.themeTemp = this.$store.state.theme;
        this.theme = this.$store.state.theme;
      }
    },
    theme(v) {
      this.$store.dispatch("set_theme", this.theme);
    }
  },
  computed: {},

  created() {},
  mounted() {},
  destroyed() {},
  methods: {
    // async getContact() {
    //   let params = { getacl: true };
    //   if (this.us_id == -1) params = {};
    //   let response = await this.$axios.get(
    //     this.$config.server_url + "/backoffice/1.0/users/" + this.us_id,
    //     { params }
    //   );
    //   let row_us = response.data.data;

    //   if (!row_us.us_id) {
    //     row_us.us_type = this.$route.meta.contactsType;
    //   }
    //   if (!row_us.us_rights) row_us.us_rights = {};
    //   for (
    //     let iRe = 0;
    //     iRe < this.$store.state.items_rights_resources.length;
    //     iRe++
    //   ) {
    //     const ressource = this.$store.state.items_rights_resources[iRe];
    //     if (!row_us.us_rights[ressource.rire_id])
    //       row_us.us_rights[ressource.rire_id] = 0;
    //   }
    //   this.row_us = row_us;
    // },

    async saveWin() {
      let response;
      response = await this.$axios.put(
        this.$config.server_url + "/backoffice/1.0/preferences/personal",
        { help: this.help, theme: this.theme }
      );
      if (response.data.err) {
        this.$store.dispatch("showDialogError", response.data.err.message);
        return;
      }
      this.$store.commit("set_user_simple", { help: this.help });
      this.$emit("saved");
    },
    cancelWin() {
      this.$store.dispatch("set_theme", this.themeTemp);
      this.$emit("input", false);
      this.$emit("canceled", this.row_us.us_id);
    }
  }
};
</script>

<style scoped lang="scss">
.modal {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.delete-avatar {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 25px;
  height: 25px;
  background-color: #c8bfbf;
  box-shadow: 0 0 2px 0 grey;
  border-radius: 50%;
  padding: 7px;
}
</style>
