import Vue from "vue";
import VueRouter from "vue-router";

import store from "./store";
import utils from "./utils";
// import config from "../config.js";
import axios from "axios";

import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
// import Autologin from "../views/Autologin.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/autologin",
    name: "autologin",
    component: Login
  },
  {
    path: "/autologin2",
    name: "autologin2",
    component: Login
  },
  {
    path: "/resetpass/:lo_id/forget",
    name: "resetpass",
    component: Login,
    meta: {}
  },
  {
    path: "/",
    alias: ["/home", "/home/cb/ok", "/home/cb/ok2", "/home/cb/ko"],
    name: "home",
    component: Home,
    meta: {}
  },
  {
    path: "/edit/participation",
    name: "EditParticipation",
    component: Home,
    meta: {}
  },
  {
    path: "/edit/wine/:id",
    name: "WineWinEdit",
    component: Home,
    meta: {}
  },
  {
    path: "/valid",
    name: "PayerWinEdit",
    component: Home,
    meta: {}
  },
  {
    path: "/orders/:id/step1",
    name: "OrderStep1",
    component: Home,
    meta: {}
  },
  {
    path: "/orders/:id/step2",
    name: "OrderStep2",
    component: Home,
    meta: {}
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach(async (to, from, next) => {
  // console.log("🚀 ~ file: router.js ~ line 82 ~ router.beforeEach ~ to", to);
  // console.log("to", to);
  /*   async function autolog() {
    // console.log("to, from", to, store.state.accesstoken);
    if (!store.state.accesstoken) {
      store.commit("set_connectedCharged");
      return false;
    }
    if (store.state.connected) return true;
    try {
      let response = await axios.post(
        process.env.VUE_APP_SERVER_URL + "/candidats/1.0/autologin",
        {
          accesstoken: store.state.accesstoken
        }
      );
      if (response.data.err) {
        return false;
      }
      store.commit("set_connexion", {
        accesstoken: response.data.accesstoken,
        refreshtoken: response.data.refreshtoken,
        usercandidat: response.data.login,
        userparticipation: response.data.participation,
        preferences: response.data.preferences
      });
      store.commit("set_connectedCharged");
      return true;
    } catch (error) {
      console.error("ko", error);
      store.commit("set_connectedCharged");
      return false;
    }
  } */
  // console.log("to", to);
  if (to.name == "resetpass") {
    store.commit("set_connectedCharged");
    return next();
  }
  if (to.name == "login") {
    store.commit("set_connectedCharged");
    return next();
  }
  if (to.name == "autologin") {
    store.commit("set_connectedCharged");
    return next();
  }
  if (to.name == "autologin2") {
    // store.commit("set_connectedCharged");
    try {
      let response = await axios.post(
        process.env.VUE_APP_SERVER_URL + "/candidats/1.0/autologin2",
        {
          token: to.query.token
        }
      );
      // console.log("response", response);
      if (response.data.err) {
        this.alert_txt = "Erreur de connexion";
        this.alert_type = "warning";
        return next({ path: "/login" });
      }
      // this.alert_txt = "Vous êtes connecté";
      // this.alert_type = "success";
      // console.log("response.data", response.data);
      store.commit("set_connexion", {
        accesstoken: response.data.accesstoken,
        refreshtoken: response.data.refreshtoken,
        usercandidat: response.data.login,
        userparticipation: response.data.participation,
        preferences: response.data.preferences
      });
      //   this.$root.$children[0].showMenus();
      store.commit("set_year", to.query.year * 1);
      return next({ path: to.query.where });
    } catch (error) {
      console.error("error", error);
      // this.alert_txt = "Erreur!!!";
      // this.alert_type = "warning";
    }
    return next({ path: "/login" });
  }
  if (!store.state.connected) {
    if (!store.state.accesstoken) {
      store.commit("set_connectedCharged");
      return next({ path: "/login" });
    }
    await utils.autolog(store.state.accesstoken);
  }
  if (to.name == "home" && store.state.connected) return next();
  if (to.name == "home" && !store.state.connected)
    return next({ path: "/login" });
  if (!store.state.connected) return next({ path: "/login" });

  return next();
});

export default router;
