<template>
  <div>
    <div class="frame">
      <h4>Paiement</h4>
      <div class="d-md-flex align-items-end">
        <m-form-select
          class="width260"
          label="Moyen de paiement"
          :name="$Utils.randomstring('in_payment')"
          v-model="row[prefix + 'typepaiement']"
          :items="$store.state.items_paiement_way"
          @input="changeInput"
          :disabled="disabledField"
        ></m-form-select>
        <m-form-text
          class="ms-md-2"
          type="number"
          label="Somme HT"
          :name="$Utils.randomstring('in_price_ht')"
          v-model="totalHT"
          @input="changeInput"
          :disabled="true"
        ></m-form-text>
        <m-form-date
          class="ms-md-2"
          label="Le (JJ/MM/AAAA)"
          :name="$Utils.randomstring('in_payed_date')"
          formatInput="DD/MM/YYYY"
          v-model="row.in_payed_date"
          @input="changeInput"
          :disabled="true"
        ></m-form-date>
        <m-form-checkbox
          class="ms-md-4 me-md-2 mb-1 mt-2 mt-md-0"
          label="Payé"
          :name="$Utils.randomstring('in_payed')"
          v-model="row[prefix + 'payed']"
          @input="changeInput"
          :disabled="true"
        ></m-form-checkbox>
        <m-form-text
          class="ms-md-2"
          label="N° banque"
          name="in_banque"
          v-model="row[prefix + 'auto']"
          @input="changeInput"
          :disabled="disabledField"
        ></m-form-text>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormInvoice",
  components: {},
  props: {
    row: Object,
    prefix: String,
    keyload: Number,
    disabledField: { type: Boolean, default: false }
  },
  data() {
    return {
      totalHT: 0
    };
  },
  watch: {
    keyload(val) {},
    row() {
      this.totalHT = this.row[this.prefix + "price_ht"] / 100;
    }
  },
  async mounted() {},
  methods: {
    changeInput() {
      this.row[this.prefix + "price_ht"] = this.totalHT * 100;
      this.$emit("changeInput", this.row);
    }
  }
};
</script>

<style lang="scss" scoped>
@media (min-width: 992px) {
  .width260 {
    width: 260px;
  }
}
</style>
