import Vue from "vue";
import Vuex from "vuex";

import _ from "lodash";
import axios from "axios";
// import config from "../config";
import dayjs from "dayjs";
dayjs.extend(require("dayjs/plugin/isSameOrAfter"));
dayjs.extend(require("dayjs/plugin/isSameOrBefore"));
dayjs.extend(require("dayjs/plugin/isBetween"));
var relativeTime = require("dayjs/plugin/relativeTime");

Vue.use(Vuex);

let accesstoken = window.localStorage.getItem("accesstoken")
  ? window.localStorage.getItem("accesstoken")
  : "";
let refreshtoken = window.localStorage.getItem("refreshtoken")
  ? window.localStorage.getItem("refreshtoken")
  : "";

/* let defaultUser = {
  co_id: "",
  co_name: "",
  co_firstname: "",
  co_function: "",
  co_mobile1: "",
  co_tel1: "",
  co_email1: "",
  co_type: ""
};
let user = _.cloneDeep(defaultUser); */

/// définition des types d'utilisateur et des routes
let usersTypes = {
  admins: { types: ["admin", "superadmin"], title: "administrateur" },
  contacts: { types: ["guest", "visitor"], title: "invité" },
  candidate: { types: ["candidate"], title: "candidat" }
};

// tableaux de colone à afficher pour les impressions de tableaux
let tablePrint_users = [
  { key: "us_type", text: "type" },
  { key: "us_civility", text: "civilité" },
  { key: "us_name", text: "nom" },
  { key: "us_firstname", text: "prénom" },
  { key: "us_email", text: "email" },
  { key: "us_active", text: "actif" },
  { key: "us_deleted", text: "supprimé" }
];
/// items pour recherche de contact mailingliste
let items_stateparticipation = [
  {
    value: "creation",
    text: "En création"
  },
  {
    value: "facture",
    text: "Facturé"
  }
];
let items_typeparticipation = [
  /*   {
    value: "tous",
    text: "Tous"
  }, */
  {
    value: "pa_candidat",
    text: "Candidats validés"
  },
  {
    value: "pa_jure",
    text: "Jurés validés"
  }
];
let items_stop = [
  /*   {
    value: "tous",
    text: "Tous"
  }, */
  {
    value: "stop",
    text: "Avec stop"
  },
  {
    value: "notStop",
    text: "Sans stop"
  }
];
let items_invoice_type = [
  /*   {
    value: "tous",
    text: "Tous"
  }, */
  {
    value: "FACTURE",
    text: "Factures"
  },
  {
    value: "AVOIR",
    text: "Avoirs"
  }
];
let items_invoice_payed = [
  /*   {
    value: "tous",
    text: "Tous"
  }, */
  {
    value: "notPayed",
    text: "Non payée"
  },
  {
    value: "payed",
    text: "Payée"
  }
];
let items_paiement = [
  /*   {
    value: "tous",
    text: "Tous"
  }, */
  {
    value: "Virement",
    text: "Virement"
  },
  {
    value: "Chèque",
    text: "Chèque"
  },
  {
    value: "CB",
    text: "CB"
  }
];
let items_stateOrder = [
  {
    value: "CREATING",
    text: "En création"
  },
  {
    value: "RECEIVED",
    text: "Réceptionnée"
  },
  {
    value: "SENDED",
    text: "Envoyée"
  }
];
let items_medailles_search = [
  {
    value: "oui",
    text: "Oui"
  },
  {
    value: "non",
    text: "Non"
  },
  {
    value: "or",
    text: "Or"
  },
  {
    value: "argent",
    text: "Argent"
  },
  {
    value: "excelence",
    text: "Excellence"
  }
];
////
let items_boolean_int = [
  {
    value: 0,
    text: "Choisir"
  },
  {
    value: 1,
    text: "Oui"
  },
  {
    value: 2,
    text: "Non"
  }
];

let items_typejure = [
  {
    value: 1,
    text: "Œnologue"
  },
  {
    value: 2,
    text: "Sommelier"
  },
  {
    value: 3,
    text: "Caviste"
  },
  {
    value: 4,
    text: "Enseignant viti-oeno"
  },
  {
    value: 5,
    text: "Etudiant viti-oeno"
  },
  {
    value: 6,
    text: "Viticulteur"
  },
  {
    value: 7,
    text: "Négociant"
  },
  {
    value: 8,
    text: "Maitre de chais"
  },
  {
    value: 9,
    text: "Acheteur/commercial en vin"
  },
  {
    value: 10,
    text: "Restaurateur"
  },
  {
    value: 11,
    text: "Journaliste"
  },
  {
    value: 12,
    text: "Agent de la fonction publique"
  },
  {
    value: 13,
    text: "Autre"
  },
];
let items_contenants = [
  { value: 1000000, text: "Vin en vrac (Hl)" },
  // { value: 1875, text: "18.75 cl" },
  // { value: 3750, text: "37,5 cl" },
  // { value: 5000, text: "50 cl" },
  { value: 7500, text: "75 cl" },
  // { value: 10000, text: "100 cl" },
  // { value: 15000, text: "150 cl" },
  // { value: 30000, text: "300 cl" },
  // { value: 50000, text: "500 cl" },
  // { value: 100000, text: "1000 cl" },
  // { value: 200000, text: "2000 cl" },
  // { value: 220000, text: "2200 cl" },
  // { value: 330000, text: "3300 cl" },
  // { value: 500000, text: "5000 cl" }
];
let items_boolean = [
  {
    value: true,
    text: "Oui"
  },
  {
    value: false,
    text: "Non"
  }
];
let items_liensJure = [
  {
    value: 2,
    text: "Aucun lien"
  },
  {
    value: 1,
    text: "Lien existant"
  }
];
let items_facture_avoir = [
  {
    value: "",
    text: "Factures et avoirs"
  },
  {
    value: "FACTURE",
    text: "Factures"
  },
  {
    value: "AVOIR",
    text: "Avoirs"
  }
];
let items_paiement_way = [
  {
    value: "Virement",
    text: "Virement"
  },
  {
    value: "Chèque",
    text: "Chèque"
  },
  {
    value: "CB",
    text: "CB"
  }
];

let items_interieurexterieur = [
  {
    value: 1,
    text: "Intérieur"
  },
  {
    value: 2,
    text: "Extérieur"
  }
];
let items_sensenroulement = [
  {
    value: 1,
    text: "Droite"
  },
  {
    value: 2,
    text: "Gauche"
  }
];
let items_medailles = [
  {
    value: 1,
    text: "Or"
  },
  {
    value: 2,
    text: "Argent"
  },
  {
    value: 3,
    text: "Excellence"
  }
];

let items_themes = [
  {
    value: "theme-0",
    text: "Pas de thème"
  },
  {
    value: "theme-1",
    text: "Couché de soleil"
  },
  {
    value: "theme-2",
    text: "Automne"
  },
  {
    value: "theme-3",
    text: "Tahiti"
  }
];
let items_rights_roles = [];
let items_rights_rolesresources = [];
let items_rights_resources = [];
let items_winesColors = [];
let items_civility = [
  {
    value: "Mme",
    text: "Madame"
  },
  {
    value: "M",
    text: "Monsieur"
  }
];

let items_winesTypes = [];
let items_winesCategories = [];
let items_winesCepages = [];

let items_log_types = [
  {
    value: "signin",
    text: "Connexion"
  },
  {
    value: "forgetpassword",
    text: "mot de passe oublié"
  },
  {
    value: "createuser",
    text: "Création d'un utilisateur"
  },
  {
    value: "edituser",
    text: "Édition d'un utilisateur"
  },
  {
    value: "deleteuser",
    text: "Suppression d'un utilisateur"
  },
  {
    value: "createcontact",
    text: "Création d'un contact"
  },
  {
    value: "editcontact",
    text: "Édition d'un contact"
  },
  {
    value: "deletecontact",
    text: "Suppression d'un contact"
  },
  {
    value: "exportMillesimes",
    text: "Export des millesimes"
  },
  {
    value: "ImportMilesimes",
    text: "Import des millesimes"
  },
  {
    value: "createInvoice",
    text: "Création d'une facture"
  },
  {
    value: "createParticipation",
    text: "Création d'une participation"
  },
  {
    value: "updateParticipation",
    text: "Édition d'une participation"
  },
  {
    value: "deleteParticipation",
    text: "Suppresion d'une participation"
  }
];

//utilisé que pour le filtre formatValue
let items_type_contacts = [
  {
    value: "superadmin",
    text: "Super administrateur"
  },
  {
    value: "admin",
    text: "Administrateur"
  },
  {
    value: "contact",
    text: "Contact"
  }
];
let year = dayjs().format("YYYY") * 1;
if (dayjs().isAfter(year + "-09-01")) {
  year = parseInt(year) + 1;
}
let currentyear = year;
if (window.localStorage.getItem("year"))
  year = parseInt(window.localStorage.getItem("year"));

// let year2 = dayjs().format("YYYY");
// if (dayjs().isAfter(year2 + "-09-01")) {
//   year2 = parseInt(year2) + 1;
// }
// let start = parseInt(year2);
// let end = 2014;
let items_years = [];
/* for (let y = start; y >= end; y--) {
  items_years.push({ value: y, text: y });
} */
let items_nbMacaron = [];
for (let nb = 5000; nb <= 240000; nb += 5000) {
  items_nbMacaron.push({
    value: nb,
    text: nb
  });
}

export default new Vuex.Store({
  state: {
    chiffres: {
      macaronsPrice: 0,
      echantillonsPrice: 0,
      tva: 0
    },
    stats: {
      candidats: {},
      wines: {},
      winesstop: {},
      jures: {},
      valid: {},
      invoices: {},
      avoirs: {},
      ca: {},
      caOrders: {},
      notPayed: {}
    },
    theme: "theme-0",
    items_themes: items_themes,
    items_boolean: items_boolean,
    items_liensJure: items_liensJure,
    items_typejure: items_typejure,
    items_boolean_int: items_boolean_int,
    items_typeparticipation: items_typeparticipation,
    items_invoice_payed: items_invoice_payed,
    items_stop: items_stop,
    items_paiement: items_paiement,
    items_stateOrder: items_stateOrder,
    items_medailles: items_medailles,
    items_sensenroulement: items_sensenroulement,
    items_interieurexterieur: items_interieurexterieur,
    items_medailles_search: items_medailles_search,
    items_invoice_type: items_invoice_type,
    items_stateparticipation: items_stateparticipation,
    items_facture_avoir: items_facture_avoir,
    items_contenants: items_contenants,
    items_paiement_way: items_paiement_way,
    reloadkey_avatar: 0,
    year: parseInt(year),
    currentyear: parseInt(currentyear),
    yearObj: {},
    items_years: items_years,
    items_nbMacaron: items_nbMacaron,
    usersTypes: usersTypes,
    tablePrint_users: tablePrint_users,
    items_log_types: items_log_types,
    items_civility: items_civility,
    items_type_contacts: items_type_contacts,
    items_rights_roles: items_rights_roles,
    items_rights_resources: items_rights_resources,
    items_rights_rolesresources: items_rights_rolesresources,
    items_winesColors: items_winesColors,
    items_winesTypes: items_winesTypes,
    items_winesCategories: items_winesCategories,
    items_winesCepages: items_winesCepages,
    accesstoken: "",
    isMainLoading: false,
    currentcontact: null,
    // lastcoid: "",
    mysocket: null,
    connected: false,
    connectedCharged: false,
    accesstoken,
    refreshtoken,
    usercandidat: {},
    userparticipation: { yearpreferences: {} },
    preferences: {
      personal: {},
      shared: {},
      rights: {}
    },
    inputOpened: null,
    dialogErr: false,
    dialogErrTxt: "",
    alertDialog: {
      value: false,
      text: "",
      type: "",
      container: ""
    },
    toasts: []
  },
  mutations: {
    set_yearobj(state, v) {
      state.yearObj = v;
    },
    set_inputOpened(state, input) {
      if (input) {
        if (state.inputOpened && input.name !== state.inputOpened.name) {
          state.inputOpened.hideDropdown();
        }
        state.inputOpened = input;
      } else {
        state.inputOpened = null;
      }
    },
    set_currentcontact(state, val) {
      state.currentcontact = val;
    },
    set_mainisloading(state, ok) {
      state.isMainLoading = ok;
    },
    set_mysocket(state, socket) {
      state.mysocket = socket;
    },
    // utiliser simplement pour mettre à jour les infos essentielles
    set_user_simple(state, login) {
      // user
      state.usercandidat.lo_name = login.lo_name;
      state.usercandidat.lo_firstname = login.lo_firstname;
      state.usercandidat.updatedAt = login.updatedAt;
      // preferences
      state.preferences.personal.help = login.help;
    },
    set_userparticipation(state, participation) {
      // user
      state.userparticipation = participation;
      // console.log("participation", participation);
      window.localStorage.setItem(
        "userparticipation",
        JSON.stringify(participation)
      );
    },
    // set_preferences_year(state, preferences) {
    //   // preferences
    //   state.preferences.year = preferences.year;
    // },
    set_acl_resources(state, acl_resources) {
      state.items_rights_resources = acl_resources;
    },
    set_tokens(state, what) {
      axios.defaults.headers.common["x-auth-accesstoken"] = what.accesstoken;
      window.localStorage.setItem("accesstoken", what.accesstoken);
      state.accesstoken = what.accesstoken;
      window.localStorage.setItem("refreshtoken", what.refreshtoken);
      state.refreshtoken = what.refreshtoken;
    },
    set_connectedCharged(state) {
      state.connectedCharged = true;
    },
    set_connexion(state, what) {
      // console.log("what", what);
      state.connectedCharged = true;
      if (!what.accesstoken) {
        axios.defaults.headers.common["x-auth-accesstoken"] = "";
        window.localStorage.removeItem("accesstoken");
        state.accesstoken = null;
        window.localStorage.removeItem("refreshtoken");
        state.refreshtoken = null;
        window.localStorage.removeItem("usercandidat");
        // window.localStorage.removeItem("userparticipation");
        state.usercandidat = {};
        state.userparticipation = { yearpreferences: {} };
        state.connected = false;
        state.items_rights_resources = [];
        state.items_rights_roles = [];
        state.items_rights_rolesresources = [];
        state.items_rights_acl = [];
        state.items_winesColors = [];
        state.items_years = [];
        state.items_winesCategories = [];
        state.items_winesCepages = [];
        state.items_winesTypes = [];
        // state.canOrderMacaron = false;
        return;
      }
      // console.log("token, refreshtoken, login", what.accesstoken, what.refreshtoken, what.login);
      axios.defaults.headers.common["x-auth-accesstoken"] = what.accesstoken;
      window.localStorage.setItem("accesstoken", what.accesstoken);
      state.accesstoken = what.accesstoken;
      window.localStorage.setItem("refreshtoken", what.refreshtoken);
      state.refreshtoken = what.refreshtoken;
      window.localStorage.setItem(
        "usercandidat",
        JSON.stringify(what.usercandidat)
      );
      // console.log("what.userparticipation", what.userparticipation);
      state.usercandidat = _.cloneDeep(what.usercandidat);
      // window.localStorage.setItem(
      //   "userparticipation",
      //   JSON.stringify(what.userparticipation)
      // );
      // state.userparticipation = _.cloneDeep(what.userparticipation);
      state.preferences = _.cloneDeep(what.preferences);
      what.preferences.itemsYearCandidat.sort((a, b) => {
        return b.value * 1 - a.value * 1;
      });
      state.items_years = what.preferences.itemsYearCandidat;
      if (state.items_years.length)
        this.dispatch("set_year", state.items_years[0].value);
      // state.canOrderMacaron = what.preferences.canOrderMacaron;
      // console.log("state.preferences", state.preferences);
      // state.year = what.preferences.year;
      // console.log("state.year", state.year);
      // state.items_rights_resources = what.preferences.rights.acl_resources;
      // state.items_rights_roles = what.preferences.rights.acl_roles;
      // console.log("state.items_rights_roles", state.items_rights_roles);
      // state.items_rights_rolesresources =
      //   what.preferences.rights.acl_rolesresources;
      // state.items_rights_acl = what.preferences.rights.acl;
      state.items_winesCategories = what.preferences.categories;
      state.items_winesTypes = what.preferences.types;
      state.items_winesCepages = what.preferences.cepages;
      state.items_winesColors = what.preferences.colors;
      // console.log("what.preferences", what.preferences);
      _.each(what.preferences.items, (val, key) => {
        state[key] = what.preferences.items[key];
      });
      state.yearObj = what.preferences.year;
      // state.theme = what.preferences.personal.theme;
      // this.commit("set_theme", what.preferences.personal.theme);
      state.connected = true;
      this.dispatch("socketjoinroom");
      //this.dispatch("updateTruc");
    },
    set_theme(state, v) {
      if (!v) v = "theme-0";
      state.theme = v;
      function setIcons() {
        let navLink = document.querySelector(".menu .nav-link");
        if (!navLink) return;
        let color = getComputedStyle(navLink).color;
        let icons = document.querySelectorAll(".menu svg");
        for (let iIcons = 0; iIcons < icons.length; iIcons++) {
          const icon = icons[iIcons];
          icon.setAttribute("color", color);
        }
      }
      setTimeout(() => {
        setIcons();
      }, 100);
      setTimeout(() => {
        setIcons();
      }, 1000);
    },
    set_year(state, v) {
      // console.log("v2", v);
      state.year = v;
      //state.year = parseInt(dayjs(v).format("YYYY"));
      window.localStorage.setItem("year", state.year);
      // console.log("state.year", state.year);
    },
    set_stats(state, stats) {
      state.stats = stats;
      // console.log("state.stats", state.stats);
    },
    set_chiffres(state, chiffres) {
      state.chiffres = chiffres;
      // console.log("state.stats", state.stats);
    },
    set_acl_rolesresources(state, what) {
      // console.log("what", what);
      state.items_rights_rolesresources = what;
    },
    set_preferences(state, data) {
      state.preferences[data.preference] = data.data;
    },
    set_dialog_error(state, what) {
      // console.log("what", what);
      state.dialogErr = what.dialogErr;
      state.dialogErrTxt = what.dialogErrTxt;
    },
    set_toast_close(state, timestamp) {
      let index = state.toasts
        .map(function (e) {
          return e.timestamp;
        })
        .indexOf(timestamp);
      state.toasts.splice(index, 1);
    },
    set_toast_open(state, what) {
      state.toasts.push(what);
    },
    set_alert_dialog(state, what) {
      // console.log("what", what);
      if (!what.container) what.container = "app";
      state.alertDialog = what;
    },
    set_reloadAvatar(state, what) {
      state.reloadkey_avatar = state.reloadkey_avatar + 1;
    }
  },
  actions: {
    set_theme(ctx, v) {
      ctx.commit("set_theme", v);
    },
    set_year(ctx, v) {
      // console.log("v", v);
      ctx.commit("set_year", v);
    },
    set_userparticipation(ctx, v) {
      // console.log("v", v);
      ctx.commit("set_userparticipation", v);
    },
    showDialogError(ctx, err) {
      ctx.commit("set_dialog_error", {
        dialogErr: err ? true : false,
        dialogErrTxt: err ? err : ""
      });
    },
    showToast(ctx, what) {
      // fermer
      if (typeof what === "number") {
        ctx.commit("set_toast_close", what);
      }
      // ouvrir
      else {
        what.timestamp = +new Date();
        dayjs.extend(relativeTime);

        ctx.commit("set_toast_open", what);
        if (!what.persist) {
          setTimeout(() => {
            ctx.commit("set_toast_close");
          }, 5000);
        }
      }
    },
    showAlertDialog(ctx, what) {
      ctx.commit("set_alert_dialog", {
        value: typeof what === "boolean" ? false : true,
        text: typeof what === "boolean" ? "" : what.text,
        type: typeof what === "boolean" ? "" : what.type,
        container: typeof what === "boolean" ? "" : what.container
      });
      if (!what.persist) {
        setTimeout(() => {
          ctx.commit("set_alert_dialog", {
            value: false,
            text: "",
            type: ""
          });
        }, 2000);
      }
    },
    socketjoinroom(ctx, socket) {
      if (socket) ctx.commit("set_mysocket", socket);
      if (
        ctx.state.usercandidat &&
        ctx.state.usercandidat.lo_id &&
        ctx.state.mysocket
      )
        ctx.state.mysocket.emit("join", ctx.state.usercandidat.lo_id);
    },
    async loadStats(ctx) {
      let response = await axios.get(
        process.env.VUE_APP_SERVER_URL + "/backoffice/1.0/homestats",
        { params: { year: ctx.state.year } }
      );
      let chiffres = {
        macaronsPrice: response.data.data.macaronsPrice,
        echantillonsPrice: response.data.data.echantillonsPrice / 100,
        tva: response.data.data.tva
      };
      ctx.commit("set_chiffres", chiffres);

      let stats = response.data.data;
      //// nb candidat
      stats.candidats.diff = Math.round(
        ((stats.candidats.nb - stats.candidats.nbMoinsN) * 100) /
        stats.candidats.nbMoinsN
      );
      stats.candidats.color = "bg-warning";
      if (isNaN(stats.candidats.diff) || !isFinite(stats.candidats.diff)) {
        stats.candidats.diff = "-";
      } else {
        if (stats.candidats.diff > 5) stats.candidats.color = "bg-success";
        if (stats.candidats.diff < -5) stats.candidats.color = "bg-danger";
      }

      //// nbJure
      stats.jures.diff = Math.round(
        ((stats.jures.nb - stats.jures.nbMoinsN) * 100) / stats.jures.nbMoinsN
      );
      stats.jures.color = "bg-warning";
      if (isNaN(stats.jures.diff) || !isFinite(stats.jures.diff)) {
        stats.jures.diff = "-";
      } else {
        if (stats.jures.diff > 5) stats.jures.color = "bg-success";
        if (stats.jures.diff < -5) stats.jures.color = "bg-danger";
      }

      //// nb vins
      stats.wines.diff = Math.round(
        ((stats.wines.nb - stats.wines.nbMoinsN) * 100) / stats.wines.nbMoinsN
      );
      stats.wines.color = "bg-warning";
      if (isNaN(stats.wines.diff) || !isFinite(stats.wines.diff)) {
        stats.wines.diff = "-";
      } else {
        if (stats.wines.diff > 5) stats.wines.color = "bg-success";
        if (stats.wines.diff < -5) stats.wines.color = "bg-danger";
      }

      //// nb vins stop
      stats.winesstop.diff = Math.round(
        ((stats.winesstop.nb - stats.winesstop.nbMoinsN) * 100) /
        stats.winesstop.nbMoinsN
      );
      stats.winesstop.color = "bg-warning";
      if (isNaN(stats.winesstop.diff) || !isFinite(stats.winesstop.diff)) {
        stats.winesstop.diff = "-";
      } else {
        if (stats.winesstop.diff > 5) stats.winesstop.color = "bg-success";
        if (stats.winesstop.diff < -5) stats.winesstop.color = "bg-danger";
      }

      stats.valid.diff = Math.round(
        ((stats.valid.nb - stats.valid.nbMoinsN) * 100) / stats.valid.nbMoinsN
      );
      stats.valid.color = "bg-warning";
      if (isNaN(stats.valid.diff) || !isFinite(stats.valid.diff)) {
        stats.valid.diff = "-";
      } else {
        if (stats.valid.diff > 5) stats.valid.color = "bg-success";
        if (stats.valid.diff < -5) stats.valid.color = "bg-danger";
      }

      //// nb invoices
      stats.invoices.diff = Math.round(
        ((stats.invoices.nb - stats.invoices.nbMoinsN) * 100) /
        stats.invoices.nbMoinsN
      );
      stats.invoices.color = "bg-warning";
      if (isNaN(stats.invoices.diff) || !isFinite(stats.invoices.diff)) {
        stats.invoices.diff = "-";
      } else {
        if (stats.invoices.diff > 5) stats.invoices.color = "bg-success";
        if (stats.invoices.diff < -5) stats.invoices.color = "bg-danger";
      }
      //// nb avoirs
      stats.avoirs.diff = Math.round(
        ((stats.avoirs.nb - stats.avoirs.nbMoinsN) * 100) /
        stats.avoirs.nbMoinsN
      );
      stats.avoirs.color = "bg-warning";
      if (isNaN(stats.avoirs.diff) || !isFinite(stats.avoirs.diff)) {
        stats.avoirs.diff = "-";
      } else {
        if (stats.avoirs.diff > 5) stats.avoirs.color = "bg-success";
        if (stats.avoirs.diff < -5) stats.avoirs.color = "bg-danger";
      }
      //// ca
      stats.ca.diff = Math.round(
        ((stats.ca.nb - stats.ca.nbMoinsN) * 100) / stats.ca.nbMoinsN
      );
      stats.ca.color = "bg-warning";
      if (isNaN(stats.ca.diff) || !isFinite(stats.ca.diff)) {
        stats.ca.diff = "-";
      } else {
        if (stats.ca.diff > 5) stats.ca.color = "bg-success";
        if (stats.ca.diff < -5) stats.ca.color = "bg-danger";
      }
      //// ca non payé
      stats.notPayed.diff = Math.round(
        ((stats.notPayed.nb - stats.notPayed.nbMoinsN) * 100) /
        stats.notPayed.nbMoinsN
      );
      stats.notPayed.color = "bg-warning";
      if (isNaN(stats.notPayed.diff) || !isFinite(stats.notPayed.diff)) {
        stats.notPayed.diff = "-";
      } else {
        if (stats.notPayed.diff > 5) stats.notPayed.color = "bg-success";
        if (stats.notPayed.diff < -5) stats.notPayed.color = "bg-danger";
      }

      //// ca commandes
      stats.caOrders.diff = Math.round(
        ((stats.caOrders.nb - stats.caOrders.nbMoinsN) * 100) /
        stats.caOrders.nbMoinsN
      );
      stats.caOrders.color = "bg-warning";
      if (isNaN(stats.caOrders.diff) || !isFinite(stats.notPayed.diff)) {
        stats.caOrders.diff = "-";
      } else {
        if (stats.caOrders.diff > 5) stats.caOrders.color = "bg-success";
        if (stats.caOrders.diff < -5) stats.caOrders.color = "bg-danger";
      }

      // console.log("stats", stats);
      ctx.commit("set_stats", stats);
    }
    //async updateTruc(ctx) {}
  },
  modules: {},
  getters: {
    get_inputOpened(state) {
      return state.inputOpened;
    }
  }
});
