import Vue from "vue";
import App from "./App.vue";
import router from "./plugins/router";
import store from "./plugins/store";
import Utils from "./plugins/utils";
import axios from "axios";
// import jquery from "jquery";
// import popper from "popper.js";
import "bootstrap";
import _ from "lodash";
import dayjs from "dayjs";
dayjs.extend(require("dayjs/plugin/isSameOrAfter"));
dayjs.extend(require("dayjs/plugin/isSameOrBefore"));
dayjs.extend(require("dayjs/plugin/isBetween"));
require("dayjs/locale/fr");
dayjs.locale("fr");
import moment from "moment";
import Validation from "./plugins/validation";
import jquery from "jquery";
import Popper from "popper.js";
import "./plugins/components";
import "./plugins/filters";
import Transitions from "vue2-transitions";
Vue.use(Transitions);

//console.log("process", process.env.NODE_ENV === "development");

// console.log("process.env", process.env);
const config = {
  server_url: process.env.VUE_APP_SERVER_URL,
  backoffice_url: process.env.VUE_APP_BACKOFFICE_URL,
  candidats_url: process.env.VUE_APP_CANDIDATS_URL,
  web_url: process.env.VUE_APP_WEB_URL,
  google_key: process.env.VUE_APP_RECAPTCHA_KEY
};

Vue.config.productionTip = false;
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;
// dayjs.locale("fr");

Object.defineProperty(Vue.prototype, "$_", { value: _ });
Object.defineProperty(Vue.prototype, "$$", { value: jquery });
Object.defineProperty(Vue.prototype, "$moment", { value: moment });
Object.defineProperty(Vue.prototype, "$dayjs", { value: dayjs });
Object.defineProperty(Vue.prototype, "$config", { value: config });
Object.defineProperty(Vue.prototype, "$Utils", { value: Utils });
Object.defineProperty(Vue.prototype, "$Validation", { value: Validation });
Object.defineProperty(Vue.prototype, "$Popper", { value: Popper });

Object.defineProperty(Vue.prototype, "$axios", { value: axios });
axios.defaults.headers.common["x-auth-accesstoken"] = store.state.accesstoken;
axios.interceptors.request.use(
  function(config2) {
    store.commit("set_mainisloading", true);
    return config2;
  },
  function(error) {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function(response) {
    store.commit("set_mainisloading", false);
    let ok = true;
    const originalRequest = response.config;
    if (response.data && response.data.err) {
      if (
        response.data.err.key == "token_mandatory" ||
        response.data.err.key == "user_not_found"
      ) {
        ok = false;
      }
      if (response.data.err.key == "token_expired") {
        return axios
          .get(
            process.env.VUE_APP_SERVER_URL + "/backoffice/1.0/refreshtoken",
            {
              headers: { "x-auth-refreshtoken": store.state.refreshtoken }
            }
          )
          .then(response2 => {
            if (!response2.data.err) {
              store.commit("set_tokens", {
                accesstoken: response2.data.accesstoken,
                refreshtoken: response2.data.refreshtoken
              });
              originalRequest.headers["x-auth-accesstoken"] =
                response2.data.accesstoken;
              return axios(originalRequest);
            }
          });
      }
    }
    if (!ok) {
      router.push("/login");
    }
    return response;
  },
  function(error) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled", error.message);
    } else {
      console.error("response error", error);
      return Promise.reject(error);
    }
  }
);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
