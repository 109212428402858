<template>
  <div class="container-fluid">
    <div class="logo-absolute text-center mb-4">
      <!--<img
        class="img-fluid"
        :src="`${$config.server_url}/backoffice/1.0/logo`"
        alt="logo"
      />-->
      <img class="img-fluid" src="/images/logo-blanc-recadre.png" alt="logo" />
    </div>

    <div class="row">
      <div
        class="col-md-12 col-lg-7 container-form-login d-flex flex-column justify-content-center "
      >
        <h3 class="bis d-flex align-items-center">
          <div
            class="icon-title d-flex align-items-center justify-content-center"
          >
            <icon width="30" height="30" name="cog"></icon>
          </div>
          <div>
            <div>Connexion à votre espace<br />candidat / juré</div>
            <div class="fs-6 fw-light"></div>
          </div>
        </h3>

        <div class="">
          <div class="d-flex mb-3">
            <div class="d-flex flex-column">
              <h1 class="mb-0"></h1>
            </div>
          </div>
          <div ref="part1" v-show="currentPart == 'part1'">
            <div class="row">
              <div class="col-md-6">
                <div class="frame h-100">
                  <form>
                    <h4 class="mb-3">J'ai un compte</h4>
                    <m-form-text
                      label="Identifiant"
                      label-position="top"
                      v-model="lo_login"
                      name="login"
                      autocomplete
                    ></m-form-text>
                    <div class="relative">
                      <m-form-text
                        class="mt-3"
                        :type="showPassword ? 'text' : 'password'"
                        label="Mot de passe"
                        label-position="top"
                        v-model="lo_password"
                        autocomplete
                        name="password"
                      ></m-form-text>
                      <div
                        class="icon-eyes pointer"
                        @click="showPassword = !showPassword"
                      >
                        <icon v-if="!showPassword" name="eye"></icon>
                        <icon v-if="showPassword" name="eye-slash"></icon>
                      </div>
                    </div>
                    <div
                      class="alert alert-danger"
                      ref="alert"
                      v-if="alert_txt"
                    >
                      {{ alert_txt }}
                    </div>
                    <div class="mt-1">
                      <a
                        class="pointer  color-red"
                        @click="currentPart = 'part2'"
                        >Mot de passe oublié</a
                      >
                    </div>
                    <button
                      type="button"
                      @click="login()"
                      class=" btn  btn-primary mt-3"
                    >
                      Se connecter
                    </button>
                  </form>
                </div>
              </div>
              <div class="col-md-6">
                <div class="frame h-100 mt-3 mt-md-0">
                  <h4 class="mb-3">Je n'ai pas de compte</h4>
                  <p v-if="row_yp.period === 1">
                    Dans le cas où vous n'avez participé à aucun Concours depuis
                    2014
                  </p>
                  <div class="d-flex mt-5">
                    <div
                      v-html="row_yp.periodText"
                      class="alert alert-danger"
                      v-if="row_yp.period !== 1"
                    ></div>
                    <button
                      v-if="row_yp.period === 1"
                      @click="subscribe()"
                      class=" btn  btn-primary "
                    >
                      M'inscrire
                    </button>
                  </div>
                  <div
                    class="alert alert-danger"
                    ref="alert"
                    v-if="signup_alert_txt"
                  >
                    {{ signup_alert_txt }}
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-4">
              <button @click="cancel()" class=" btn  btn-secondary ">
                Annuler
              </button>
            </div>
          </div>

          <div ref="part2" v-show="currentPart == 'part2'">
            <m-form-text
              label="Identifiant ou Email"
              label-position="top"
              v-model="lo_login"
              name="login"
              autocomplete
            ></m-form-text>
            <div ref="alert" class="alert alert-danger" v-if="alert_txt_part2">
              {{ alert_txt_part2 }}
            </div>
            <div>
              <button
                @click="currentPart = 'part1'"
                class="btn btn-secondary mt-3"
              >
                Annuler
              </button>
              <button @click="sendPassword" class="btn btn-primary ms-2 mt-3">
                Envoyer le mot de passe
              </button>
            </div>
          </div>

          <div ref="part3" v-show="currentPart == 'part3'">
            Nous venons de vous envoyer un email vous permettant de mettre à
            jour votre mot de passe. <br />
            La réception peut prendre parfois quelques minutes. <br />
            En cas de non réception, vérifiez dans votre boite SPAMS. <br />
            <div>
              <button
                @click="currentPart = 'part1'"
                class="btn btn-primary mt-3"
              >
                Se connecter maintenant
              </button>
            </div>
          </div>

          <div ref="part4" v-show="currentPart == 'part4'">
            <div
              class="alert alert-danger mt-2 mb-0"
              ref="alert"
              v-if="alert_txt_part4"
            >
              {{ alert_txt_part4 }}
            </div>
            <div>
              <m-form-password
                :showRules="true"
                valid="shadow"
                display="col"
                :rules="rulesPassword"
                :required="true"
                @validPassword="validPassword"
              ></m-form-password>
              <button @click="newpassword" class="ms-auto btn btn-primary mt-2">
                Redéfinir le nouveau mot de passe
              </button>
            </div>
          </div>

          <div ref="part5" v-show="currentPart == 'part5'">
            C'est ok !
            <br />Votre nouveau mot de passe a bien été enregistré. <br />
            Vous pouvez maintenant vous connecter.
            <div>
              <button
                @click="currentPart = 'part1'"
                class="btn btn-primary mt-3"
              >
                Se connecter maintenant
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <candidat-jure-win-edit
      v-model="candidatjureWinEdit"
      :pa_id="-1"
      :signup="true"
      :row_yp="row_yp"
      @WinEditAction="candidatJureWinEditAction"
    >
    </candidat-jure-win-edit>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "login",
  components: {},
  data() {
    return {
      currentPart: "part1",
      //societyName: "",
      logoUrl: "",
      lo_login: "",
      lo_password: "",
      password1: "",
      showPassword: false,
      rulesPassword: {
        nbCharacteres: 8,
        specialCharactere: true,
        uppercase: true,
        number: true
      },
      isValidPassword: false,
      errPassword: [],
      alert_txt: "",
      alert_type: "warning",
      signup_alert_txt: "",
      signup_alert_type: "warning",
      alert_txt_part2: "",
      alert_type_part2: "warning",
      alert_txt_part4: "",
      alert_type_part4: "warning",
      challenge_newpassword: "",
      lo_id: "",
      candidatjureWinEdit: false,
      row_yp: {},
      InscriptionClosed: false
    };
  },
  async mounted() {
    window.addEventListener("keyup", this.onKeyup);

    let response = await this.$axios.get(
      this.$config.server_url + "/candidats/1.0/preferencesyear"
    );
    this.row_yp = response.data.data;
    console.log(
      "🚀 ~ file: Login.vue ~ line 262 ~ mounted ~ this.row_yp",
      this.row_yp
    );
    // console.log("row_yp", this.row_yp.periodText);

    // let lastInscriptionDate = this.$dayjs(
    //   this.row_yp.yp_end_inscription_date_candidate
    // ).isAfter(this.row_yp.yp_end_inscription_date_jure)
    //   ? this.row_yp.yp_end_inscription_date_candidate
    //   : this.row_yp.yp_end_inscription_date_jure;
    // if (
    //   this.$dayjs()
    //     .startOf("day")
    //     .isAfter(lastInscriptionDate)
    // )
    //   this.InscriptionClosed = true;

    //  this.$root.$children[0].hideMenus();
    //  this.$root.$children[0].resizeMainContainer();
    if (this.$route.name == "resetpass") {
      this.currentPart = "part4";
      this.checkchallenge(
        this.$route.params.lo_id,
        this.$route.query.challenge
      );
    }
    if (this.$route.name == "autologin") {
      // console.log("this.$route.query", this.$route.query);
      try {
        let response = await this.$axios.post(
          process.env.VUE_APP_SERVER_URL + "/candidats/1.0/login",
          {
            lo_login: this.$route.query.login,
            lo_pass: this.$route.query.password
          }
        );
        // console.log("response", response);
        if (response.data.err) {
          this.alert_txt = "Erreur de connexion";
          this.alert_type = "warning";
          return;
        }
        // this.alert_txt = "Vous êtes connecté";
        // this.alert_type = "success";
        // console.log("response.data", response.data);
        this.$store.commit("set_year", this.$route.query.year * 1);
        this.$store.commit("set_connexion", {
          accesstoken: response.data.accesstoken,
          refreshtoken: response.data.refreshtoken,
          usercandidat: response.data.login,
          userparticipation: response.data.participation,
          preferences: response.data.preferences
        });
        this.$store.commit("set_year", this.$route.query.year * 1);
        //   this.$root.$children[0].showMenus();
        this.$router.push("/home");
      } catch (error) {
        console.error("error", error);
        // this.alert_txt = "Erreur!!!";
        // this.alert_type = "warning";
      }
    }

    if (this.$route.name == "autologin2") {
      console.log("this.$route.query", this.$route.query);
      try {
        let response = await this.$axios.post(
          process.env.VUE_APP_SERVER_URL + "/candidats/1.0/login",
          {
            lo_login: this.$route.query.login,
            lo_pass: this.$route.query.password
          }
        );
        // console.log("response", response);
        if (response.data.err) {
          this.alert_txt = "Erreur de connexion";
          this.alert_type = "warning";
          return;
        }
        // this.alert_txt = "Vous êtes connecté";
        // this.alert_type = "success";
        // console.log("response.data", response.data);
        this.$store.commit("set_year", this.$route.query.year * 1);
        this.$store.commit("set_connexion", {
          accesstoken: response.data.accesstoken,
          refreshtoken: response.data.refreshtoken,
          usercandidat: response.data.login,
          userparticipation: response.data.participation,
          preferences: response.data.preferences
        });
        this.$store.commit("set_year", this.$route.query.year * 1);
        //   this.$root.$children[0].showMenus();
        this.$router.push("/home");
      } catch (error) {
        console.error("error", error);
        // this.alert_txt = "Erreur!!!";
        // this.alert_type = "warning";
      }
    }
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Enter") {
        this.login();
      }
    },
    // getTxt() {
    //   let txt = "";
    //   if (
    //     this.$dayjs()
    //       .startOf("day")
    //       .isSameOrAfter(this.$dayjs(this.$dayjs().format("YYYY") + "-01-09"))
    //   ) {
    //     txt =
    //       "Les inscriptions pour le concours " +
    //       (this.row_yp.yp_year + 1) +
    //       " ne sont pas encore ouvertes.";

    //     if (
    //       this.row_yp.nextConcours &&
    //       this.row_yp.nextConcours.yp_start_inscription_date
    //     )
    //       txt +=
    //         "</br>Ouverture des inscriptions : Le " +
    //         this.$dayjs(
    //           this.row_yp.nextConcours.yp_start_inscription_date
    //         ).format("DD MMMM");
    //   } else {
    //     txt =
    //       "Les inscriptions pour le concours " +
    //       this.row_yp.yp_year +
    //       " sont closes";
    //   }
    //   return txt;
    // },
    cancel() {
      window.location.href = this.$config.web_url + "/";
    },
    async subscribe() {
      this.candidatjureWinEdit = true;
    },
    async candidatJureWinEditAction(what) {
      if (what.action === "canceled") return;
      let ok = await this.$Utils.autolog(what.login.lo_accesstoken);
      if (ok) this.$router.push("/home");
    },
    validPassword(isValid, errors, password) {
      this.isValidPassword = isValid;
      this.errPassword = errors;
      this.password1 = password;
    },

    async newpassword() {
      if (!this.challenge_newpassword) return;
      this.alert_txt_part4 = "";
      if (!this.isValidPassword) {
        for (let ierr = 0; ierr < this.errPassword.length; ierr++) {
          this.alert_txt_part4 += this.errPassword[ierr] + " ";
        }
      }
      if (!this.alert_txt_part4) {
        let response = await this.$axios.post(
          this.$config.server_url +
            "/candidats/1.0/signin/forgetpassword/newpassword",
          {
            lo_password: this.password1,
            lo_id: this.lo_id,
            challenge: this.challenge_newpassword
          }
        );
        if (response.data.err) {
          this.alert_txt_part4 =
            "Une erreur est survenue, veuillez recommencer la procédure";
        } else {
          this.currentPart = "part5";
        }
      }
    },

    async checkchallenge(lo_id, challenge) {
      // console.log("lo_id, challenge", lo_id, challenge);
      this.lo_id = lo_id;
      let response = await this.$axios.post(
        this.$config.server_url +
          "/candidats/1.0/signin/forgetpassword/checkchallenge",
        { lo_id: lo_id, challenge }
      );
      if (response.data.err) {
        this.alert_txt_part4 =
          "Une erreur est survenue, veuillez recommencer la procédure";
      } else {
        // this.currentPart = "part3";
        this.challenge_newpassword = response.data.data.challenge;
      }
    },

    async sendPassword() {
      try {
        await this.$axios.post(
          this.$config.server_url + "/candidats/1.0/forgetpassword",
          { lo_login: this.lo_login }
        );
      } catch (error) {
        console.warn("error", error);
        this.alert_txt_part2 =
          "Nous n'avons pas pu retrouver votre identifiant";
        return;
      }
      this.currentPart = "part3";
    },

    async login() {
      // alert("ici");
      try {
        let response = await this.$axios.post(
          this.$config.server_url + "/candidats/1.0/login",
          { lo_login: this.lo_login, lo_pass: this.lo_password }
        );
        // console.log("response.data", response.data);
        if (response.data.err) {
          this.alert_txt = "Erreur de connexion";
          this.alert_type = "warning";
          return;
        }
        this.alert_txt = "Vous êtes connecté";
        this.alert_type = "success";
        //console.log("response.data", response.data);
        this.$store.commit("set_connexion", {
          accesstoken: response.data.accesstoken,
          refreshtoken: response.data.refreshtoken,
          usercandidat: response.data.login,
          userparticipation: response.data.participation,
          preferences: response.data.preferences
        });
        //   this.$root.$children[0].showMenus();
        this.$router.push("/home");
      } catch (error) {
        console.error("error", error);
        this.alert_txt = "Erreur!!!";
        this.alert_type = "warning";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container-form-login {
  padding: 15px;
  margin: auto;
  background-color: #efefef;
  border-radius: 6px;
}
.logo-absolute {
  position: relative;
  width: 200px;
  margin: auto;
}
@media (min-width: 992px) {
  .container-form-login {
    padding: 40px;
  }
  .logo-absolute {
    position: absolute;
    top: 10px;
    left: 10px;
  }
}
</style>
