<template>
  <div
    class="modal"
    v-if="value"
    :style="
      value ? 'display:block;background-color:#3333337a;' : 'display:none;'
    "
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex flex-row align-items-center">
          <h3 class="mb-0 d-flex align-items-center">
            <div
              class="icon-title d-flex align-items-center justify-content-center"
            >
              <icon width="30" height="30" name="user-friends"></icon>
            </div>
            <div>
              <!-- grand écran -->
              <div class="d-none d-md-block">
                Votre inscription candidat/juré au Concours
                {{ row_pa.yearpreferences.yp_year }}
              </div>
              <div class="fs-6 fw-light d-none d-md-block">
                Les champs avec une astérisque sont obligatoires
              </div>
              <!-- petit écran -->
              <div class="d-md-none">
                Votre inscription {{ row_pa.yearpreferences.yp_year }}
              </div>
              <div class="fs-6 fw-light d-md-none">
                * champs obligatoires
              </div>
            </div>
          </h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <form-candidat
                ref="formCondidatJure"
                :pa_id="pa_id"
                :signup="signup"
                :row_yp="row_yp"
                from="candidats"
                @formCandidatJureActions="emitFormCandidatJureActions"
                @participationLoaded="emitPparticipationLoaded"
              ></form-candidat>
              <div class="frame ">
                <h4>Email et mot de passe</h4>
                <div class="d-md-flex">
                  <m-form-text
                    label="Email * (sera votre identifiant)"
                    class="width330"
                    :name="$Utils.randomstring('lo_login')"
                    v-model="row_pa.login.lo_login"
                  ></m-form-text>
                  <!-- grand écran le display change (row)-->
                  <m-form-password
                    class="ms-2 d-none d-md-block"
                    style="width:50%"
                    :name="$Utils.randomstring('pass')"
                    :showRules="true"
                    valid="shadow"
                    display="row"
                    :rules="rulesPassword"
                    :required="false"
                    @validPassword="validPassword"
                  ></m-form-password>
                  <!-- petit écran le display change (col)-->
                  <m-form-password
                    class=" d-md-none"
                    :name="$Utils.randomstring('pass')"
                    :showRules="true"
                    valid="shadow"
                    display="col"
                    :rules="rulesPassword"
                    :required="false"
                    @validPassword="validPassword"
                  ></m-form-password>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer d-flex flex-row">
          <div class="ms-auto"></div>
          <button type="button" class="btn btn-secondary " @click="cancelWin">
            Annuler
          </button>
          <button
            type="button"
            class="btn btn-primary ms-2"
            @click="tryToSaveWin"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CandidatJureWinEdit",
  components: {},
  props: {
    pa_id: Number,
    signup: Boolean,
    row_yp: Object,
    value: { default: false, type: Boolean }
  },
  data() {
    return {
      row_pa: { login: {}, yearpreferences: {} },
      //mot de passe
      password1: "",
      isValidPassword: true,
      errPassword: [],
      rulesPassword: {
        nbCharacteres: 8,
        specialCharactere: true,
        uppercase: true,
        number: true
      }
    };
  },
  watch: {
    value(v) {
      if (v) {
        // console.log("this.pa_id bis", this.pa_id);
        this.row_pa = { login: {}, yearpreferences: {} };
        this.jureValideConfirmed = false;
        // this.loadCandidat();
      }
    }
  },
  computed: {},
  created() {},
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    async loadCandidat() {
      /*       if (!this.signup) {
        this.cancelAxiosLoadJures && this.cancelAxiosLoadJures();
        let response = await this.$axios.get(
          this.$config.server_url +
            "/candidats/1.0/participations/" +
            this.pa_id,
          {
            cancelToken: new this.$axios.CancelToken(c => {
              this.cancelAxiosLoadJures = c;
            })
          }
        );
        if (!response || !response.data) return;
        this.row_pa = response.data.data;
        if (!this.row_pa.login) this.row_pa.login = { lo_pass: "" };
        this.row_pa.login.lo_pass = "";
      } */
    },
    emitPparticipationLoaded(row_pa) {
      // console.log("this.row_pa2", row_pa.yearpreferences);
      this.row_pa = row_pa;
      if (!this.row_pa.login) this.row_pa.login = { lo_pass: "" };
      this.row_pa.login.lo_pass = "";
    },
    validPassword(isValid, errors, password) {
      this.isValidPassword = isValid;
      this.errPassword = errors;
      this.password1 = password;
    },
    tryToSaveWin() {
      /*** vérification du mot de passe */
      /*       if (!this.isValidPassword) {
        for (let ierr = 0; ierr < this.errPassword.length; ierr++) {
          this.$store.dispatch("showToast", {
            title: "Mot de passe invalide ",
            text: this.errPassword[ierr],
            color: "red"
          });
        }
      }  else {*/
      /*       if (!this.row_pa.login.lo_login) {
        this.$store.dispatch("showToast", {
          title: "login invalide ",
          text: "Vous devez renseigner un login",
          color: "red"
        });
      } else { */
      // this.row_pa.login.lo_login = this.row_pa.login.lo_login.trim();
      this.row_pa.login.lo_pass = this.password1;
      this.$refs.formCondidatJure.tryToSaveWin(
        this.row_pa.login,
        this.errPassword
      );
      //}
      /* } */
    },
    emitFormCandidatJureActions(what) {
      this.$emit("input", false);
      if (this.signup) {
        if (what.action === "saved")
          this.$emit("WinEditAction", {
            action: "saved",
            login: what.row_lo
          });
        if (what.action === "canceled")
          this.$emit("WinEditAction", {
            action: "canceled"
          });
      } else {
        if (what.action === "saved") {
          // this.$store.dispatch("set_userparticipation", what.row_pa);
          this.$emit("WinEditAction", { action: "saved", row_pa: what.row_pa });
        }
      }
    },
    cancelWin() {
      this.$emit("input", false);
      this.$emit("WinEditAction", { action: "canceled" });
    }
  }
};
</script>

<style scoped lang="scss">
.modal-dialog {
  .modal-content {
    background-color: #eceff0;
  }
  .modal-header,
  .modal-footer {
    border: 0;
  }
}
@media (min-width: 768px) {
  .width330 {
    width: 330px;
  }
}
</style>
