/* import axios from "axios";
// import _ from "lodash"; */
import store from "./store";
import axios from "axios";

const Utils = {
  async autolog(accesstoken) {
    // console.log("accesstoken", accesstoken);
    /*     if (!store.state.accesstoken) {
      store.commit("set_connectedCharged");
      return false;
    } */
    if (store.state.connected) return true;
    try {
      let response = await axios.post(
        process.env.VUE_APP_SERVER_URL + "/candidats/1.0/autologin",
        {
          accesstoken: accesstoken
        }
      );
      if (response.data.err) {
        return false;
      }
      store.commit("set_connexion", {
        accesstoken: response.data.accesstoken,
        refreshtoken: response.data.refreshtoken,
        usercandidat: response.data.login,
        userparticipation: response.data.participation,
        preferences: response.data.preferences
      });
      store.commit("set_connectedCharged");
      return true;
    } catch (error) {
      console.error("ko", error);
      store.commit("set_connectedCharged");
      return false;
    }
  },

  randomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  },

  randomstring(keep = "", length = 12) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    if (keep) result = keep + "__" + result;
    // console.log('result',result);
    return result;
  },

  async wait(funcToTest, maxTime) {
    return new Promise(function (resolve, reject) {
      let timeoutTest, timeoutMaxTime;
      function test() {
        timeoutTest = window.setTimeout(() => {
          if (funcToTest()) {
            if (timeoutMaxTime) window.clearTimeout(timeoutMaxTime);
            return resolve(true);
          }
          test();
        }, 10);
      }
      timeoutMaxTime = window.setTimeout(() => {
        if (timeoutTest) window.clearTimeout(timeoutTest);
        console.warn("timeoutmax");
        resolve(false);
      }, maxTime);
      test();
    });
  },
  /*   async doRequestIo(method, url, params) {
    try {
      let response = await axios[method](
        process.env.VUE_APP_SERVER_URL + url,
        params ? params : null,
        {
          headers: {
            "x-auth-accesstoken": store.state.accesstoken,
            "Access-Control-Allow-Origin": "*"
          }
        }
      );
      return response.data;
    } catch (e) {
      console.error("Error Ajax REquest - :", e);
      return null;
    }
  }, */

  isAdmin(user, prefix) {
    if (!user) user = store.state.user;
    if (!user) return false;
    if (!prefix) prefix = "us_";
    return (
      user[prefix + "type"] === "admin" ||
      user[prefix + "type"] === "superadmin"
    );
  },

  formatedPhone(mobile) {
    // console.log("mobile", mobile);
    let formated = "";
    if (mobile.split("")[0] === "0") {
      let tabPos = [11, 8, 5, 2];
      for (let i = 0; i < tabPos.length; i++) {
        const pos = tabPos[i];
        if (mobile.length === pos) {
          let val1 = mobile.split("");
          val1.push(" ");
          formated = val1.join("");
        }
      }
    }
    if (mobile.split("")[0] === "+") {
      let tabPos = [13, 10, 7, 4];
      for (let i = 0; i < tabPos.length; i++) {
        const pos = tabPos[i];
        if (mobile.length === pos) {
          let val1 = mobile.split("");
          val1.push(" ");
          formated = val1.join("");
        }
      }
    }
    if (formated.length) return formated;
    else return mobile;
  },

  /**
   *
   * @param {string} mobile
   * @returns string , le mobile formaté avec des espaces
   * soit 06 00 00 00 00 ou +336 00 00 00 00 00
   */
  addSpaceToMobile(mobile) {
    let mobileFormated = "";
    function insert_spaces(text, pos) {
      let liste_temp = text.split("");
      for (let i = 0; i < pos.length; i++) {
        const x = pos[i];
        liste_temp.splice(x, 0, " ");
      }
      return liste_temp.join("");
    }

    let patern0 = /^0/;
    let patern33 = /^\+33/;
    const regex0 = new RegExp(patern0);
    const regex33 = new RegExp(patern33);
    if (regex0.test(mobile)) {
      mobileFormated = insert_spaces(mobile, [10, 8, 6, 4, 2]);
    }
    if (regex33.test(mobile)) {
      mobileFormated = insert_spaces(mobile, [10, 8, 6, 4]);
    }
    return mobileFormated;
  },

  /**
   *
   * @param {string} mobile
   * @returns le mobile formaté sans espace
   */
  removeSpaceToMobile(mobile) {
    return mobile
      .trim()
      .split(" ")
      .join("");
  }
};
export default Utils;
