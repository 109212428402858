import { render, staticRenderFns } from "./PayerWinEdit.vue?vue&type=template&id=2961e390&scoped=true"
import script from "./PayerWinEdit.vue?vue&type=script&lang=js"
export * from "./PayerWinEdit.vue?vue&type=script&lang=js"
import style0 from "./PayerWinEdit.vue?vue&type=style&index=0&id=2961e390&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2961e390",
  null
  
)

export default component.exports