<template>
  <div
    class="modal"
    v-if="value"
    :style="
      value ? 'display:block;background-color:#3333337a;' : 'display:none;'
    "
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex align-items-center ">
          <div>
            <h3 class="mb-0 d-flex align-items-center">
              <div
                class="icon-title d-flex align-items-center justify-content-center"
              >
                <icon width="30" height="30" name="shopping-cart"></icon>
              </div>
              <div>
                <div v-if="row_or.or_state == 'CREATING'">
                  Valider une commande
                </div>
                <div v-if="row_or.or_state == 'RECEIVED'">
                  Payer une commande
                </div>
                <div class="fs-6 fw-light">
                  Étape 2 / 2 - Une fois validée, la commande n'est plus
                  modifiable.
                </div>
              </div>
            </h3>
          </div>
        </div>
        <div class="modal-body">
          <div class="frame bg-gray">
            <div class="row">
              <div class="col-md-12">
                <!-- <choice-candidat
                  :row_pa="row_pa2"
                  :medailles="true"
                  @emitChangeCandidat="emitChangeCandidat"
                  @emitEditCandidatJure="emitEditCandidatJure"
                ></choice-candidat> -->
                <read-order-macaron
                  :row_pa="row_pa2"
                  :row_or="row_or"
                  :canReturnStep1="row_or.or_state !== 'SENDED'"
                  @emitStep1="goStep1"
                  @editCandidatJure="editCandidatJure"
                ></read-order-macaron>
              </div>
            </div>
          </div>
          <div class="frame">
            <h4>La commande</h4>
            <p>
              Liste des macarons que vous avez commandés
            </p>
            <m-list-simple
              tableClass="table-hover table-striped"
              ref="wineslist"
              :dynamic="true"
              :items="row_or.orderlines"
              item-value="orl_id"
              :item-height="50"
              :total="row_or.orderlines.length"
            >
              <template v-slot:ths="{}"> </template>
              <template v-slot:tds="{ item }">
                <td class="d-md-flex align-items-center">
                  <div class="p-2">
                    <img
                      class="img-fluid img-macaron"
                      :src="
                        `${
                          $config.server_url
                        }/commons/1.0/files/yp_file_macaron_${$options.filters.formatvalue(
                          item.wine.wi_medaille,
                          'items_medailles',
                          'lowercase',
                          'singular'
                        )}/${item.wine.wi_year}`
                      "
                      alt="image macaron"
                    />
                  </div>
                  <div class="ms-md-3">
                    <div>{{ item.wine.wi_name }}</div>
                    <h5>
                      <span
                        >{{
                          item.wine.denomination
                            ? item.wine.denomination.de_name
                            : ""
                        }}
                        -
                      </span>
                      <span
                        >{{ item.wine.wi_couleur | formatWineColor }} -
                      </span>
                      <span>{{
                        item.wine.wi_millesime | formatWineMillesime
                      }}</span>
                    </h5>
                    <div>
                      Nombre de vins déclarés :
                      <div
                        v-for="(contenant, index) in item.wine.contenants"
                        :key="index"
                      >
                        {{ contenant.nombre }} x
                        {{ contenant.contenant / 100 }} cl
                      </div>
                    </div>
                  </div>
                  <!-- petits écrans -->
                  <div class="d-md-none">
                    <div>Concours {{ item.wine.wi_year }}</div>
                    <div>
                      Macarons
                      {{
                        $options.filters.formatvalue(
                          item.wine.wi_medaille,
                          "items_medailles",
                          "uppercase",
                          "singular"
                        )
                      }}
                      : {{ item.orl_quantity | formatChiffreSimple }}
                    </div>
                  </div>
                </td>
                <!-- grands écrans -->
                <td class="align-middle d-none d-md-table-cell">
                  <div>Concours {{ item.wine.wi_year }}</div>
                </td>
                <td class="align-middle d-none d-md-table-cell">
                  <div>
                    Macarons
                    {{
                      $options.filters.formatvalue(
                        item.wine.wi_medaille,
                        "items_medailles",
                        "uppercase",
                        "singular"
                      )
                    }}
                    : {{ item.orl_quantity | formatChiffreSimple }}
                  </div>
                </td>
              </template>
            </m-list-simple>
            <results-order-macaron
              :row_or="row_or"
              from="candidats"
            ></results-order-macaron>
          </div>
          <!--  <form-invoice
            :row="row_or"
            prefix="or_"
            :disabledField="row_or.or_state === 'SENDED'"
            @changeInput="emitChangeInputPaiement"
          ></form-invoice> -->
        </div>
        <div class="modal-footer d-flex flex-row">
          <!--  <button
            v-if="row_or.or_state !== 'SENDED'"
            type="button"
            class="btn btn-outline-danger"
            @click="deleteConfirmWin"
          >
            Supprimer
          </button> -->
          <div class="ms-auto"></div>
          <!-- <div class="d-flex align-items-center">
            <div class="me-2">Envoyer email reception</div>
            <label class="switch">
              <input v-model="sendEmailReception" type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div> -->
          <button type="button" class="btn btn-secondary " @click="cancelWin">
            Annuler
          </button>
          <button
            v-if="row_or.or_state === 'CREATING'"
            type="button"
            class="btn btn-secondary "
            @click="goStep1"
          >
            Retour 1 / 2
          </button>
          <button
            type="button"
            class="btn btn-primary ms-2"
            @click="saveWin"
            v-if="row_or.or_state === 'CREATING'"
          >
            Valider
          </button>
          <form
            method="POST"
            :action="serverpaybox"
            name="myForm"
            v-if="row_or.or_state == 'RECEIVED'"
          >
            <div v-html="formcb"></div>
            <button class="btn btn-lg btn-primary  ms-3 btn-sizing">
              Paiement par carte bancaire
            </button>
          </form>

          <!-- <button
            type="button"
            class="btn btn-primary ms-2"
            @click="payNow"
            v-if="row_or.or_state === 'RECEIVED'"
          >
            Payer
          </button> -->
        </div>
      </div>
    </div>
    <!--     <m-confirm-dialog
      v-model="confirmdelete"
      text="Souhaitez-vous effacer cette commande ?"
      title="Confirmation"
      @canceled="confirmdelete = false"
      @confirmed="deleteWin()"
    ></m-confirm-dialog> -->
    <candidat-jure-win-edit
      v-if="row_pa2"
      v-model="candidatJureWinEdit"
      :pa_id="row_pa2.pa_id"
      @WinEditAction="WinEditCandidatJureActions"
    ></candidat-jure-win-edit>
    <m-message-dialog
      v-model="dialogErr"
      title="Erreur"
      :text="dialogErrTxt"
    ></m-message-dialog>
  </div>
</template>

<script>
export default {
  name: "OrderNew2",
  components: {},
  props: {
    or_id: Number,
    row_pa: {
      default: function() {
        return {};
      },
      type: Object
    },
    value: { default: false, type: Boolean }
  },
  data() {
    return {
      // confirmdelete: false,
      dialogErrTxt: "",
      dialogErr: false,
      row_or: { orderlines: [] },
      wines: [],
      row_pa2: {},
      candidatJureWinEdit: false,
      sendEmailReception: true,
      isValidated: false,
      formcb: "",
      serverpaybox: ""
    };
  },
  watch: {
    value(v) {
      if (v) {
        if (this.row_pa) this.row_pa2 = this.row_pa;
        this.showFraisDePort = false;
        this.loadOrder();
        //this.contenants = [];
        //  this.loadDenominations();
        //this.loadWines();
      }
    }
  },
  computed: {},
  created() {},
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    async downloadFormCb() {
      let response = await this.$axios.post(
        // this.$config.server_url + "/candidats/1.0/participations/" + this.pa_id,
        this.$config.server_url + "/candidats/1.0/paybox/order/form",
        { or_id: this.row_or.or_id }
      );
      // console.log("downloadFormCb", response.data);
      this.formcb = response.data.data.form;
      this.serverpaybox = response.data.data.serverpaybox;
    },
    async payNow() {},
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    cancelWin() {
      this.$emit("input", false);
      this.$emit("WinEditActions", { action: "canceled" });
    },
    async loadOrder() {
      let response = await this.$axios.get(
        this.$config.server_url + "/candidats/1.0/orders/" + this.or_id
      );
      let row_or = response.data.data;
      // calcul contenance
      for (let iwi = 0; iwi < row_or.orderlines.length; iwi++) {
        const orl = row_or.orderlines[iwi];
        orl.wine.nbBouteille = 0;
        orl.wine.contenants = [];
        if (orl.wine.wi_contenances) {
          let tabC = orl.wine.wi_contenances.split(";;");
          for (let i = 0; i < tabC.length; i++) {
            const el = tabC[i];
            if (el.length) {
              let tab2 = el.split(":");
              //wi.nbBouteille += parseInt(tab2[1]);
              orl.wine.contenants.push({
                contenant: parseInt(tab2[0]),
                nombre: parseInt(tab2[1])
              });
            }
          }
        }
      }
      this.row_or = row_or;
      // console.log("this.row_or", this.row_or);
      // if (this.row_or.or_fr) this.isValidated = false;
      // else this.isValidated = true;
      // console.log("this.row_or", this.row_or.or_price_ht);
      this.row_pa2 = this.row_or.participation;
      // console.log("this.row_or.or_state", this.row_or.or_state);
      if (this.row_or.or_state === "RECEIVED") this.downloadFormCb();
    },
    editCandidatJure() {
      this.candidatJureWinEdit = true;
      //this.$router.push("/candidats/" + this.row_pa.pa_id + "/edit");
    },
    emitChangeInputPaiement(row_or) {
      this.row_or = row_or;
    },
    /* async loadWines() {
      if (!this.row_pa2.pa_id) return;
      let params = {
        medailles: true,
        wi_year: this.$store.state.year,
        pa_id: this.row_pa2.pa_id
      };
      if (this.wi_id == -1) params = {};
      let response = await this.$axios.get(
        this.$config.server_url + "/backoffice/1.0/wines",
        { params }
      );
      let rows_wi = response.data.data;
      // contenances
      for (let iwi = 0; iwi < rows_wi.length; iwi++) {
        const wi = rows_wi[iwi];
        wi.contenants = [];
        if (wi.wi_contenances) {
          let tabC = wi.wi_contenances.split(";;");
          for (let i = 0; i < tabC.length; i++) {
            const el = tabC[i];
            if (el.length) {
              let tab2 = el.split(":");
              wi.contenants.push({
                contenant: parseInt(tab2[0]),
                nombre: parseInt(tab2[1])
              });
            }
          }
          // this.calculContenance();
        }
      }
      this.wines = rows_wi;
    }, */

    /*     emitChangeCandidat(row_pa) {
      this.row_pa2 = row_pa;
      this.row_or.participation = row_pa;
      this.loadWines();
      this.$refs.winesListMacaron.resetNbMacaron();
    }, */
    emitEditCandidatJure() {
      this.candidatJureWinEdit = true;
    },
    async saveWin() {
      this.row_or.sendEmailReception = this.sendEmailReception;
      let response = await this.$axios.put(
        this.$config.server_url +
          "/candidats/1.0/orders/" +
          this.row_or.or_id +
          "/step2",
        this.row_or
      );
      this.$emit("input", false);
      this.$emit("WinEditActions", {
        action: "saved",
        data: response.data.data
      });
      // this.dialogErr = true;
      // this.dialogErrTxt =
      //   "Nous vous remercions pour votre commande de macarons. Notre équipe va valider cette commande et calculer les frais de port. Sitôt fait, vous recevrez alors un email vous proposant de payer par Carte bancaire cette commande";
    },

    goStep1() {
      this.$emit("input", false);
      this.$emit("returnStep1", this.row_or);
    },
    /*     deleteConfirmWin() {
      this.confirmdelete = true;
    },
    async deleteWin() {
      await this.$axios.delete(
        this.$config.server_url + "/candidats/1.0/orders/" + this.row_or.or_id
      );
      this.confirmdelete = false;
      this.$emit("WinEditActions", { action: "deleted" });
    }, */
    WinEditCandidatJureActions(data) {
      if (data.action === "saved") {
        this.row_pa2 = data.row_pa;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.img-macaron {
  width: 50px;
}
@media (min-width: 992px) {
  .img-macaron {
    width: 80px;
  }
}
</style>
