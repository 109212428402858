import { render, staticRenderFns } from "./CandidatJureWinEdit.vue?vue&type=template&id=45eda763&scoped=true"
import script from "./CandidatJureWinEdit.vue?vue&type=script&lang=js"
export * from "./CandidatJureWinEdit.vue?vue&type=script&lang=js"
import style0 from "./CandidatJureWinEdit.vue?vue&type=style&index=0&id=45eda763&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45eda763",
  null
  
)

export default component.exports