<template>
  <div
    class="modal"
    v-if="value"
    :style="
      value ? 'display:block;background-color:#3333337a;' : 'display:none;'
    "
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="bis d-flex">
            <div
              class="icon-title d-flex align-items-center justify-content-center"
            >
              <img
                class="img-fluid "
                src="/images/icons/impression.png"
                alt=""
              />
            </div>
            <div>
              <div>
                Télécharger les fichiers d'impression
              </div>
              <div class="fs-6 fw-light d-none d-md-block">
                Si vous souhaitez faire imprimer vos macarons par votre
                imprimeur pour les années où vous avez gagné une médaille.
              </div>
              <div class="fs-6 fw-light d-md-none">
                Pour faire imprimer vos macarons par votre imprimeur.
              </div>
            </div>
          </h3>
        </div>
        <div class="modal-body">
          <div v-if="years.length == 0">
            Vous n'avez aucun vin gagnant trouvé.
          </div>
          <m-list-simple
            v-else
            tableClass="table-hover table-striped"
            style="overflow: visible;"
            ref="wineslist"
            :items="years"
            item-value="wi_id"
            :total="years.length"
          >
            <template v-slot:ths="{}"> </template>
            <template v-slot:tds="{ item }">
              <td class="fw-bold fst-italic align-middle">
                Fichier des macarons {{ item.wi_year }}
              </td>
              <td class="align-middle">
                <button
                  @click="downloadFiles(item)"
                  class="btn btn-primary btn-sm"
                >
                  Télécharger
                </button>
              </td>
            </template>
          </m-list-simple>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" @click="deleteWin">Annuler</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrintMacaronFilesDialog",
  props: {
    value: Boolean,
    years: Array
  },

  data() {
    return {
      dialog: false
    };
  },
  mounted() {},
  watch: {
    value: function(val) {
      this.dialog = val;
      // if (!this.width) this.width = "500px";
    }
  },
  components: {},
  methods: {
    downloadFiles(item) {
      window.open(
        `${this.$config.server_url}/commons/1.0/files/yp_file_macaron_impression/${item.wi_year}?origin=${this.$config.candidats_url}&user=${this.$store.state.usercandidat.lo_id}`,
        "_blank"
      );
    },
    deleteWin() {
      this.dialog = false;
      this.$emit("input", false);
      this.$emit("close");
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-content {
  margin: auto;
  width: 100%;
}
.icon-title {
  width: 90px;
  img {
    width: 50px;
  }
}
@media (min-width: 992px) {
  .modal-content {
    width: 810px;
  }
}
</style>
