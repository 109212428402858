<template>
  <div
    class="modal"
    v-if="value"
    :style="
      value ? 'display:block;background-color:#3333337a;' : 'display:none;'
    "
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header d-flex align-items-center ">
          <div>
            <h3 class="mb-0 d-flex align-items-center">
              <div
                class="icon-title d-flex align-items-center justify-content-center"
              >
                <icon width="30" height="30" name="shopping-cart"></icon>
              </div>
              <div>
                <div>
                  {{
                    row_or.or_id > 0
                      ? "Modification d'une commande"
                      : "Création d'une commande"
                  }}
                </div>
                <div class="fs-6 fw-light">
                  Étape 1 / 2
                </div>
              </div>
            </h3>
          </div>
        </div>
        <div class="modal-body">
          <div class="frame bg-gray">
            <div class="row">
              <div class="col-md-3">
                <h4>Données</h4>
                <m-form-date
                  class="mb-2"
                  label="Date de la commande"
                  :name="$Utils.randomstring('or_date')"
                  formatInput="DD/MM/YYYY"
                  v-model="row_or.or_date"
                ></m-form-date>
                <!-- <div class="d-flex w-100"> -->
                <div>
                  <img
                    src="/images/schema-enroulement-B.jpeg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <m-form-select
                  label="Enroulement *"
                  class="mb-2"
                  :items="$store.state.items_interieurexterieur"
                  v-model="row_or.or_interieurexterieur"
                  :name="$Utils.randomstring('or_interieurexterieur')"
                ></m-form-select>
                <m-form-select
                  label="Sens d'enroulement *"
                  class="mb-2"
                  :items="$store.state.items_sensenroulement"
                  v-model="row_or.or_sensenroulement"
                  :name="$Utils.randomstring('or_sensenroulement')"
                ></m-form-select>
                <!-- </div> -->
                <m-form-text
                  label="Mandrin (en mm) *"
                  type="number"
                  v-model="row_or.or_mandrin"
                  :name="$Utils.randomstring('or_mandrin')"
                ></m-form-text>
              </div>
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-6">
                    <h4>Adresses de livraison</h4>
                    <m-form-text
                      class="mb-2"
                      label="Nom de l'entreprise ou de l'exploitation *"
                      :name="$Utils.randomstring('or_society_liv')"
                      v-model="row_or.or_society_liv"
                    ></m-form-text>
                    <div class="d-flex mb-2">
                      <div class="">
                        <m-form-select
                          class="mb-2"
                          style="min-width:125px;"
                          label="Civilité *"
                          :items="$store.state.items_civility"
                          :name="$Utils.randomstring('or_civility_liv')"
                          v-model="row_or.or_civility_liv"
                        ></m-form-select>
                      </div>
                      <div class="mx-1">
                        <m-form-text
                          label="Nom *"
                          :name="$Utils.randomstring('or_name_liv')"
                          v-model="row_or.or_name_liv"
                        ></m-form-text>
                      </div>
                      <div class="">
                        <m-form-text
                          label="Prénom *"
                          :name="$Utils.randomstring('or_firstname_liv')"
                          v-model="row_or.or_firstname_liv"
                        ></m-form-text>
                      </div>
                    </div>
                    <m-form-text
                      class="mb-1"
                      label="Adresse *"
                      :name="$Utils.randomstring('or_address1_liv')"
                      v-model="row_or.or_address1_liv"
                    ></m-form-text>
                    <m-form-text
                      class="mb-2"
                      :name="$Utils.randomstring('or_address2_liv')"
                      v-model="row_or.or_address2_liv"
                    ></m-form-text>
                    <div class="d-flex mb-2">
                      <div class="">
                        <m-form-text
                          label="Code postal *"
                          :name="$Utils.randomstring('or_zip_liv')"
                          v-model="row_or.or_zip_liv"
                        ></m-form-text>
                      </div>
                      <div class="ms-2 *">
                        <m-form-text
                          label="Ville *"
                          :name="$Utils.randomstring('or_city_liv')"
                          v-model="row_or.or_city_liv"
                        ></m-form-text>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <h4>Adresse de facturation</h4>
                    <m-form-text
                      class="mb-2"
                      label="Nom de l'entreprise ou de l'exploitation *"
                      :name="$Utils.randomstring('or_society_fac')"
                      v-model="row_or.or_society_fac"
                    ></m-form-text>
                    <m-form-text
                      class="mb-1"
                      label="Adresse *"
                      :name="$Utils.randomstring('or_address1_fac')"
                      v-model="row_or.or_address1_fac"
                    ></m-form-text>
                    <m-form-text
                      class="mb-2"
                      :name="$Utils.randomstring('or_address2_fac')"
                      v-model="row_or.or_address2_fac"
                    ></m-form-text>
                    <div class="d-flex mb-2">
                      <div class="">
                        <m-form-text
                          label="Code postal *"
                          :name="$Utils.randomstring('or_zip_fac')"
                          v-model="row_or.or_zip_fac"
                        ></m-form-text>
                      </div>
                      <div class="ms-2">
                        <m-form-text
                          label="Ville *"
                          :name="$Utils.randomstring('or_city_fac')"
                          v-model="row_or.or_city_fac"
                        ></m-form-text>
                      </div>
                    </div>
                    <div class="d-flex mb-2">
                      <div class="">
                        <m-form-text
                          label="Tél mobile *"
                          :name="$Utils.randomstring('or_phone_mobile')"
                          v-model="row_or.or_phone_mobile"
                        ></m-form-text>
                      </div>
                      <div class="ms-2 *">
                        <m-form-text
                          label="Tél fixe"
                          :name="$Utils.randomstring('or_phone_fix')"
                          v-model="row_or.or_phone_fix"
                        ></m-form-text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="frame">
            <h4>La commande</h4>
            <p>
              Liste des vins où vous avez gagné une médaille, sélectionnez le
              nombre de macarons à commander par vin.
            </p>
            <m-list-simple
              tableClass="table-hover table-striped"
              style="overflow: visible;"
              ref="wineslist"
              :dynamic="true"
              :items="wines"
              item-value="wi_id"
              :item-height="50"
              :total="wines.length"
            >
              <template v-slot:ths="{}"> </template>
              <template v-slot:tds="{ item }">
                <td class="d-md-flex align-items-center">
                  <div class="p-2">
                    <img
                      class="img-fluid img-macaron"
                      :src="
                        `${
                          $config.server_url
                        }/commons/1.0/files/yp_file_macaron_${$options.filters.formatvalue(
                          item.wi_medaille,
                          'items_medailles',
                          'lowercase',
                          'singular'
                        )}/${item.wi_year}`
                      "
                      alt="image macaron"
                    />
                  </div>
                  <div class="ms-md-3">
                    <div>{{ item | formatWineName }}</div>
                    <h5>
                      <span>{{ item.denomination.de_name }} -</span>
                      <span>{{ item.wi_couleur | formatWineColor }} -</span>
                      <span>{{ item.wi_millesime | formatWineMillesime }}</span>
                    </h5>
                    <div>
                      Nombre de vins déclarés :
                      <!-- {{ item.nbBouteille }} -->
                      <div
                        v-for="(contenant, index) in item.contenants"
                        :key="index"
                      >
                        {{ contenant.nombre }} x
                        {{ contenant.contenant / 100 }} cl
                      </div>
                    </div>
                  </div>
                  <!-- petits écrans -->
                  <div class="d-md-none">
                    <div>Concours {{ item.wi_year }}</div>
                    <m-form-select
                      label="Nombre de macarons"
                      :name="$Utils.randomstring('nbMacaron')"
                      :items="$store.state.items_nbMacaron"
                      v-model="item.nbMacaron"
                      @input="calculNbMacaron(item)"
                    ></m-form-select>
                  </div>
                </td>
                <!-- grnads écrans -->
                <td class="align-middle d-none d-md-table-cell">
                  <div>Concours {{ item.wi_year }}</div>
                </td>
                <td class="align-middle d-none d-md-table-cell">
                  <m-form-select
                    label="Nombre de macarons"
                    :name="$Utils.randomstring('nbMacaron')"
                    :items="$store.state.items_nbMacaron"
                    v-model="item.nbMacaron"
                    @input="calculNbMacaron(item)"
                  ></m-form-select>
                </td>
              </template>
            </m-list-simple>
            <results-order-macaron
              ref="resultMacaron"
              :row_or="row_or"
              from="candidats"
              @changetotalOrder="emitChangetotalOrder"
            ></results-order-macaron>
          </div>
        </div>
        <div class="modal-footer d-flex flex-row">
          <div class="ms-auto"></div>

          <button type="button" class="btn btn-secondary " @click="cancelWin">
            Annuler
          </button>
          <button
            type="button"
            class="btn btn-primary ms-2"
            @click="tryToSaveWin"
          >
            Étape 2 / 2
          </button>
        </div>
      </div>
    </div>
    <m-message-dialog
      v-model="dialogErr"
      title="Erreur"
      :text="dialogErrTxt"
    ></m-message-dialog>
  </div>
</template>

<script>
// import MFormText from "../components/MFormText.vue";
export default {
  name: "OrderNew1",
  components: {},
  props: {
    or_id: Number,
    row_pa: {
      default: function() {
        return {};
      },
      type: Object
    },
    value: { default: false, type: Boolean }
  },
  data() {
    return {
      // confirmdelete: false,
      dialogErrTxt: "",
      dialogErr: false,
      row_or: { or_or: 0, or_argent: 0, or_excellence: 0 },
      wines: [],
      row_pa2: {},
      totalMacaron: 0,
      totalPriceHT: 0,
      fraisDePort: 0
    };
  },
  watch: {
    async value(v) {
      if (v) {
        if (this.row_pa) this.row_pa2 = this.row_pa;
        this.wines = [];
        this.row_or = { or_or: 0, or_argent: 0, or_excellence: 0 };
        await this.loadOrder();
        if (this.row_pa && !this.row_or.or_id) {
          // console.log("je passe");
          // copie de l'adressse
          this.row_or.or_society_fac = this.row_pa.pa_society_fac
            ? this.row_pa.pa_society_fac
            : this.row_pa.pa_society;
          this.row_or.or_address1_fac = this.row_pa.pa_address1_fac
            ? this.row_pa.pa_address1_fac
            : this.row_pa.pa_address1;
          this.row_or.or_address2_fac = this.row_pa.pa_address2_fac
            ? this.row_pa.pa_address2_fac
            : this.row_pa.pa_address2;
          this.row_or.or_city_fac = this.row_pa.pa_city_fac
            ? this.row_pa.pa_city_fac
            : this.row_pa.pa_city;
          this.row_or.or_zip_fac = this.row_pa.pa_zip_fac
            ? this.row_pa.pa_zip_fac
            : this.row_pa.pa_zip;
          this.row_or.or_society_liv = this.row_pa.pa_society;
          this.row_or.or_civility_liv = this.row_pa.pa_civility;
          this.row_or.or_name_liv = this.row_pa.pa_name;
          this.row_or.or_firstname_liv = this.row_pa.pa_firstname;
          this.row_or.or_address1_liv = this.row_pa.pa_address1;
          this.row_or.or_address2_liv = this.row_pa.pa_address2;
          this.row_or.or_city_liv = this.row_pa.pa_city;
          this.row_or.or_zip_liv = this.row_pa.pa_zip;
        }
        this.loadWines();
      }
    }
  },
  computed: {},
  created() {},
  mounted() {
    window.addEventListener("keyup", this.onKeyup);
  },
  destroyed() {
    window.removeEventListener("keyup", this.onKeyup);
  },
  methods: {
    onKeyup(e) {
      if (e.key === "Escape") {
        this.cancelWin();
      }
    },
    calculNbMacaron(wine) {
      // calcul le nombre de macaron demandé par couleur (or argent excellence) et enregistre directement dan row_or
      let nb = 0;
      for (let iwi = 0; iwi < this.wines.length; iwi++) {
        const row_wi = this.wines[iwi];
        if (row_wi.wi_medaille === wine.wi_medaille) {
          if (row_wi.nbMacaron) nb += row_wi.nbMacaron;
        }
      }
      // console.log("nb", nb);
      let medaille = this.$options.filters.formatvalue(
        wine.wi_medaille,
        "items_medailles",
        "lowercase",
        "singular"
      );
      this.row_or["or_" + medaille] = nb;
      this.$refs.resultMacaron.calculTotal();
    },
    /*     calculTotal() {
      this.totalMacaron =
        this.row_or.or_or + this.row_or.or_argent + this.row_or.or_excellence;
      // console.log("this.totalMacaron", this.totalMacaron);
      this.totalPriceHT =
        (this.totalMacaron / 10000) * this.row_or.yp_macarons_price;
      // console.log(
      //   "this.$store.state.chiffres.macaronsPrice",
      //   this.$store.state.chiffres.macaronsPrice
      // );
    }, */
    async loadOrder() {
      // console.log("je passe", this.or_id);
      if (this.or_id) {
        let response = await this.$axios.get(
          this.$config.server_url + "/candidats/1.0/orders/" + this.or_id
        );
        this.row_or = response.data.data;
      } /* else {
        let response = await this.$axios.get(
          this.$config.server_url +
            "/candidats/1.0/orders/new?year=" +
            this.$store.state.year
        );
        this.row_or = response.data.data;
      } */
      // this.totalPriceHT = this.row_or.or_price_ht ;
      // if (this.row_or.participation) this.row_pa2 = this.row_or.participation;
      // else this.row_pa2 = { login: {} };

      // await this.loadWines();
      // this.calculTotal();
      // this.totalMacaron =
      //   this.row_or.or_or + this.row_or.or_argent + this.row_or.or_excellence;
      // this.totalPriceHT =
      //   (this.totalMacaron / 10000) * this.$store.state.chiffres.macaronsPrice;
    },
    emitChangetotalOrder(results) {
      this.row_or.or_price_ht = results.ht;
      this.row_or.or_price_ttc = results.ttc;
      this.row_or.or_price_tva = results.tva;
    },
    async loadWines() {
      let response = await this.$axios.get(
        this.$config.server_url + "/candidats/1.0/wines/logins"
      );
      let rows_wi = response.data.data;
      // contenances (nombre de boutilles totale)
      for (let iwi = 0; iwi < rows_wi.length; iwi++) {
        const wi = rows_wi[iwi];
        wi.nbBouteille = 0;
        wi.contenants = [];
        if (wi.wi_contenances) {
          let tabC = wi.wi_contenances.split(";;");
          for (let i = 0; i < tabC.length; i++) {
            const el = tabC[i];
            if (el.length) {
              let tab2 = el.split(":");
              //wi.nbBouteille += parseInt(tab2[1]);
              wi.contenants.push({
                contenant: parseInt(tab2[0]),
                nombre: parseInt(tab2[1])
              });
            }
          }
        }
      }
      // si on a une order on remplis les nombre de macaron par vin indiqué dans les orderlines
      if (this.or_id && this.row_or.orderlines) {
        for (let iwi = 0; iwi < rows_wi.length; iwi++) {
          const row_wi = rows_wi[iwi];
          for (let iorl = 0; iorl < this.row_or.orderlines.length; iorl++) {
            const row_orl = this.row_or.orderlines[iorl];
            if (row_wi.wi_id === row_orl.wine.wi_id)
              row_wi.nbMacaron = row_orl.orl_quantity;
          }
        }
      }

      this.wines = rows_wi;
    },

    tryToSaveWin() {
      let err = [];
      let fieldRequired = [
        { field: "or_sensenroulement", text: "Sens d'enroulement" },
        { field: "or_interieurexterieur", text: "Enroulement" },
        { field: "or_mandrin", text: "Mandrin" },
        { field: "or_society_liv", text: "Société de livraison" },
        { field: "or_civility_liv", text: "Civilité de livraison" },
        { field: "or_name_liv", text: "Nom de livraison" },
        { field: "or_firstname_liv", text: "Prénom de livraison" },
        { field: "or_address1_liv", text: "Adresse de livraison" },
        { field: "or_zip_liv", text: "Code postal de livraison" },
        { field: "or_city_liv", text: "Ville de livraison" },
        { field: "or_society_fac", text: "Société de facturation" },
        { field: "or_address1_fac", text: "Adresse de facturation" },
        { field: "or_zip_fac", text: "Code postal de facturation" },
        { field: "or_city_fac", text: "Ville de facturation" },
        { field: "or_phone_mobile", text: "Tél mobile" }
      ];
      for (let ifi = 0; ifi < fieldRequired.length; ifi++) {
        const field = fieldRequired[ifi];
        if (!this.row_or[field.field]) err.push(field);
      }
      if (
        !this.row_or.or_or &&
        !this.row_or.or_argent &&
        !this.row_or.or_excellence
      ) {
        err.push({
          text: "Vous devez sélectionner au moins un nombre de macarons"
        });
      }
      if (err.length) {
        this.dialogErrTxt = "";
        if (!this.row_pa2.pa_id) {
          this.dialogErrTxt +=
            "<span class='fw-bold mb-1'>Vous devez sélectionner un participant</span><br>";
        }
        this.dialogErrTxt +=
          "<span class='fw-bold'>Les champs suivants sont obligatoires : </span><br>";
        for (let ierr = 0; ierr < err.length; ierr++) {
          const error = err[ierr];
          this.dialogErrTxt += "• " + error.text + " <br>";
        }
        this.dialogErr = true;
        return;
      }
      this.saveWin();
    },
    async saveWin() {
      this.row_or.wines = [];
      for (let iW = 0; iW < this.wines.length; iW++) {
        const row_wi = this.wines[iW];
        this.row_or.wines.push({
          wi_id: row_wi.wi_id,
          nbMacaron: row_wi.nbMacaron
        });
      }
      this.row_or.or_fraisport = this.fraisDePort;
      this.row_or.lo_id = this.$store.state.usercandidat.lo_id;
      this.row_or.year = this.$store.state.year;
      let response;
      if (this.row_or.or_id) {
        response = await this.$axios.put(
          this.$config.server_url +
            "/candidats/1.0/orders/" +
            this.or_id +
            "/step1",
          this.row_or
        );
      } else {
        response = await this.$axios.post(
          this.$config.server_url + "/candidats/1.0/orders/step1",
          this.row_or
        );
      }
      this.$emit("input", false);
      this.$emit("WinEditActions", {
        action: "saved",
        data: response.data.data
      });
    },

    cancelWin() {
      this.$emit("input", false);
      this.$emit("WinEditActions", { action: "canceled" });
    },

    WinEditCandidatJureActions(data) {
      if (data.action === "saved") {
        this.row_pa2 = data.row_pa;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.img-macaron {
  width: 50px;
}
@media (min-width: 992px) {
  .img-macaron {
    width: 80px;
  }
}
</style>
