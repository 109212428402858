<template>
  <div class="help">
    <button
      class="btn btn-primary btn-sm"
      @click="showHelpVariables = !showHelpVariables"
    >
      Ajouter des variables dans l'email
    </button>
    <div v-if="showHelpVariables" class="mt-2">
      <table class="table table-bordered table-striped table-small">
        <thead class="thead-light">
          <tr>
            <th class="text-center">Texte à incérer</th>
            <th class="text-center">Affichage</th>
            <th class="text-center">Résultat (exemple)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ openMustache }}completeName{{ closeMustache }}</td>
            <td>Nom complet du destinataire</td>
            <td>Mme DUPONT Marie</td>
          </tr>
          <tr>
            <td>{{ openMustache }}name{{ closeMustache }}</td>
            <td>Nom du destinataire en majuscule</td>
            <td>DUPONT</td>
          </tr>
          <tr>
            <td>{{ openMustache }}firstname{{ closeMustache }}</td>
            <td>Prénom du destinataire</td>
            <td>Marie</td>
          </tr>
          <tr>
            <td>{{ openMustache }}civility{{ closeMustache }}</td>
            <td>Civilité du destinataire</td>
            <td>Mme</td>
          </tr>
          <tr>
            <td>{{ openMustache }}echantillons_price{{ closeMustache }}</td>
            <td>prix d'un échantillon</td>
            <td>50€</td>
          </tr>
          <tr>
            <td>{{ openMustache }}date_start{{ closeMustache }}</td>
            <td>Date de début du concours</td>
            <td>02/02/2021</td>
          </tr>
          <tr>
            <td>{{ openMustache }}date_end{{ closeMustache }}</td>
            <td>Date de fin du concours</td>
            <td>06/02/2021</td>
          </tr>
          <tr>
            <td>{{ openMustache }}date_result{{ closeMustache }}</td>
            <td>Date des résultats</td>
            <td>10/02/2021</td>
          </tr>
          <tr>
            <td>{{ openMustache }}macarons_price{{ closeMustache }}</td>
            <td>Prix du macaron</td>
            <td>10€</td>
          </tr>
          <tr>
            <td>
              {{ openTripleMustache }}wines{{ closeTripleMustache }} <br />
              (trois acolades)
            </td>
            <td>La liste des vins présentés par le candidat suivi des prix</td>
            <td>
              <div>Rappel de votre inscription :</div>
              <ul class="mt-2">
                <li>
                  Dénomination : Gaillac <br />
                  Couleur : rouge <br />
                  Millesime : 2019 <br />
                  Cépages : cépages <br />
                  Référence du contenant : blabbla <br />
                  Volume total du lot : 500 hl <br />
                </li>
              </ul>
              <div>
                Nombre de vin(s) présenté(s) : 1 <br />
                Prix unitaire HT : 50€ <br />
                Prix total HT : 50€ <br />
                TVA 20% : 10€ <br />
                Total TTC : 60€
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "helpVariables",
  components: {},
  props: {},
  data() {
    return {
      showHelpVariables: false,
      openMustache: "{{",
      closeMustache: "}}",
      openTripleMustache: "{{{",
      closeTripleMustache: "}}}"
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: {}
};
</script>

<style scoped lang="scss">
.help {
  border: 1px solid #eaeaea;
  padding: 5px;
}
</style>
